<template>
	<div
		data-component="hand"
		:data-selected="getIsSelected"
		:data-highlighted="getIsHighlighted"
		:data-answer="isAnswerDiagram"
		:data-selectable="getIsSelectable"
		:data-selected-and-correct-and-quiz-is-complete="getIsSelectedAndCorrectAndQuizIsComplete"
		:data-selected-and-incorrect-and-quiz-is-complete="getIsSelectedAndIncorrectAndQuizIsComplete"
		@click="selectAnswer"
	>
		<p
			v-if="getShowEmptyMessage"
			data-element="empty-message"
		>
			Empty hand
		</p>
		<counter
			v-if="isEditMode"
			:uuid="uuid"
			:position="position"
			:isValid="isValid"
			:errors="errors"
			align="left"
		/>
		<div data-element="suits">
			<suit
				v-for="(suit, key) in suitOrder"
				:key="key"
				:uuid="uuid"
				:suit="suit"
				:cards="getHand(suit)"
				:position="position"
				:isEditMode="isEditMode"
				:isPartOfDealMatrix="isPartOfDealMatrix"
				:isSimulatorRemote="isSimulatorRemote"
				:isPartOfQuiz="isPartOfQuiz"
				:hideEmptySuits="getHideEmptySuits"
			/>
		</div>
		<checkbox-field
			v-if="isEditMode && !isPartOfDealMatrix"
			name="Hide empty suits"
			:id="`check-${uuid}`"
			v-model="getHideEmptySuits"
		/>
		<checkbox-field
			v-if="isEditMode && isAnswerDiagram"
			name="Correct answer"
			:id="`check-${uuid}`"
			v-model="getIsCorrect"
		/>
		<answer-icon
			v-if="getAnswerIcon"
			:icon="getAnswerIcon"
		/>
	</div>
</template>

<script>

	import Counter         from '@/components/diagrams/Counter';
	import Suit            from '@/components/diagrams/Suit';
	import CheckboxField   from '@/components/forms/CheckboxField';
	import AnswerIcon      from '@/components/activities/view/activityTypes/quiz/AnswerIcon';
	import quizAnswer      from '@/mixins/quizAnswer';
	import quizAnswerIcon  from '@/mixins/quizAnswerIcon';
	import { suitOrder }   from '@/consts';

	export default {
		components: {
			Counter,
			Suit,
			CheckboxField,
			AnswerIcon
		},
		mixins: [quizAnswer, quizAnswerIcon],
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isEditMode: {
				type: Boolean,
				default: false
			},
			isThumbnail: {
				type: Boolean,
				default: false
			},
			isAnswerDiagram: {
				type: Boolean,
				default: false
			},
			isValid: {
				type: Boolean,
				default: true
			},
			errors: {
				type: Object,
				default: undefined
			},
			hand: {
				type: Object,
				default: () => {}
			},
			position: {
				type: String,
				default: 'north'
			},
			isPartOfDealMatrix: {
				type: Boolean,
				default: false
			},
			isSimulatorRemote: {
				type: Boolean,
				default: false
			},
			isPartOfQuiz: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			suitOrder
		}),
		computed: {
			getShowEmptyMessage () {
				if (!this.isThumbnail) {
					return false;
				}
				if (this.getNumberOfCardsInHand) {
					return false;
				}
				return true;
			},
			getNumberOfCardsInHand () {
				return this.$store.getters['quiz/getNumberOfCardsInHandByUuidAndPosition']({
					uuid: this.uuid,
					position: this.position
				});
			},
			getHideEmptySuits: {
				get () {
					return this.$store.getters['quiz/getHideEmptySuitsByUuid']({
						uuid: this.uuid
					});
				},
				set (value) {
					return this.$store.commit('quiz/setHideEmptySuitsByUuid', {
						uuid: this.uuid,
						hideEmptySuits: value
					});
				}
			}
		},
		watch: {},
		mounted () {},
		methods: {
			getHand (suit) {
				if (!this.hand) {
					return '';
				}
				return this.hand[suit];
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='hand'] {
    user-select:none;
    padding: 16px;
    border-radius: 5px;
    border: 1px solid $c-gray-l;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 50px;

    @media (max-width: 1279px) {
      min-width: auto;
    }

    [data-element='suits'] {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

		[data-element="empty-message"] {
			font-size:rem(18);
			margin:0 0 rem(8);
		}

		&[data-answer] {
			position:relative;
			padding:rem(12);
			border-radius:4px;
			border:3px solid transparent;
			&[data-selectable] {
				cursor:pointer;
			}
			&[data-selected] {
				background-color:lighten($c-brand-blue-lighter-1, 50%);
				border-color:$c-brand-blue-lighter-1;
				cursor:default;
			}
			&[data-selected-and-correct-and-quiz-is-complete] {
				background-color:lighten($c-brand-green, 50%);
				border-color:$c-brand-green;
			}
			&[data-selected-and-incorrect-and-quiz-is-complete] {
				background-color:lighten($c-brand-red, 50%);
				border-color:$c-brand-red;
				&[data-selectable] {
					cursor:pointer;
				}
			}
			&[data-highlighted] {
				border-color:$c-brand-green;
			}
			[data-component='answer-icon'] {
				position:absolute;
				bottom:rem(4);
				right:rem(4);
			}
		}

		[data-element='empty-message'] {
			font-size:rem(18);
			margin:0 0 rem(8);
		}

		[data-component='counter'] {
			width:100%;
			margin:0 0 rem(10);
		}
	}

</style>
