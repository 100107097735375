<template>
	<component
		@click="handleClick"
		:is="to ? 'router-link' : 'button'"
		class="btn"
		:class="[variant, size, {disabled}, {fit}]"
		:to="to"
		:type="type"
		:disabled="disabled"
	>
		<slot />
	</component>

</template>

<script >
	export default {
		emits: ['click'],
		props: {
			variant: {
				type: String,
				default: 'filled'
			},
			disabled: {
				type: Boolean,
				default: false
			},
			to: {
				default: ''
			},
			url: {
				type: String,
				default: ''
			},
			type: {
				type: String,
				default: 'button'
			},
			fit: {
				type: Boolean,
				default: false
			},
			size: {
				type: String,
				default: ''
			}
		},

		methods: {
			handleClick () {
        if (this.url) {
          const a = document.createElement('a');
          a.href = this.url;
          a.target = '_blank';
          a.click();

          return;
        }
        if (!this.to) {
          this.$emit('click');
        }
			}
		}
	};

</script>

<style scoped lang="scss">

.btn {
  width: 100%;
  border-radius: 4px;
  @include font(16px, $c-dark-navy, 500, 20px);
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover:not(.disabled) {
    filter: brightness(80%);
  }

  &.disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }

  &.fit {
    width: fit-content;
  }

  &.red {
    background: $c-red;
    color: $c-white-l;
    border: 1px solid $c-red;
  }

  &.outline {
    background: $c-bg-white;
    color: $c-dark-navy;
    border: 1px solid $c-dark-navy;
  }

  &.filled {
    background: $c-dark-navy;
    color: $c-white-l;
    border: 1px solid $c-dark-navy;
  }

  &.white {
    background-color: $c-white-l;
    color: $c-dark-navy;
  }

  &.text {
    color: $c-dark-navy;
    background: none;
    text-align: center;

    &:hover:not(.disabled) {
      background: $c-gray-l2;
      border: 1px solid $c-gray-l2;
    }
  }

  &.correct {
    background: $c-green-l2;
    color: $c-darkest-navy;
    border: 1px solid $c-green-l2;
  }

  &.incorrect {
    background: $c-red-l2;
    color: $c-darkest-navy;
    border: 1px solid $c-red-l2;
  }

  &.xs {
    // used for add/remove link buttons
    // in teacher profile
    height:rem(28);
    padding:0 rem(10);
    font-size:rem(11);
    [data-element='spinner'] {
      width:rem(16);
      margin:auto 0 auto rem(2);
    }
  }
  &.sm {
    // used for action groups
    height:rem(30);
    padding:0 rem(12);
    font-size:rem(14);
    [data-element='spinner'] {
      width:rem(16);
      margin:auto 0 auto rem(4);
    }
  }
  &.lg {
    // used for action lists
    height:rem(44);
    padding:0 rem(20);
    font-size:rem(16);
    border-radius:8px;
    [data-element='spinner'] {
      width:rem(24);
      margin:auto 0 auto rem(8);
    }
  }
  &.xl {
    // used for main cta on course and lesson view
    height:rem(50);
    padding:0 rem(24);
    font-size:rem(20);
    border-radius:8px;
    [data-element='spinner'] {
      width:rem(28);
      margin:auto 0 auto rem(10);
    }
  }
}
</style>
